import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/wellington_panorama_cropped.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Contact us today | Window Magic</title>
        <meta
          name="description"
          content="Have a question? We’d love to hear from you. Send us a message and we’ll respond as soon as possible."
        />
      </Helmet>

      <Layout hidePageHeader={true}>
        <PageHeader
          fluid={data.file.childImageSharp.fluid}
          alt="Wellington City"
          title="Contact"
        />

        <section className="page-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto mb-5 text-center">
                <p className="h5 pb-4">
                  Have a question? We’d love to hear from you. Send us a message
                  and we’ll respond as soon as possible.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="mb-gutter col-sm-6 col-lg-3 text-center">
                <i className="fas mb-3 fa-2x fa-phone"></i>
                <h5>Phone Number</h5>
                <a href="tel:+64274449914">027 444 9914</a>
              </div>
              <div className="mb-gutter col-sm-6 col-lg-3 text-center">
                <i className="fas mb-3 fa-2x fa-envelope"></i>
                <h5>Email</h5>
                <a href="mailto:greg@windowmagic.co.nz">
                  greg@windowmagic.co.nz
                </a>
              </div>
              <div className="mb-gutter col-sm-6 col-lg-3 text-center">
                <i className="fas mb-3 fa-2x fa-map-marker-alt"></i>
                <h5>Location</h5>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.google.com/maps?ll=-41.076511,174.870837&z=18&t=m&hl=en&gl=NZ&mapclient=embed&q=4%20Freshfields%20Place%20Plimmerton%20Porirua%205026"
                >
                  Unit 1, 4 Freshfields Place Plimmerton, Porirua 5026
                </a>
              </div>
              <div className="mb-gutter col-sm-6 col-lg-3 text-center">
                <i className="fas mb-3 fa-2x fa-clock"></i>
                <h5>Opening Hours</h5>
                <span>Weekdays: 09:00 – 17:00</span>
                <br />
                <span>Saturday: 09:00 – 15:00</span>
                <br />
                <span>Sunday: CLOSED</span>
              </div>
            </div>
          </div>
        </section>

        <section id="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d751.915696354692!2d174.87004142925818!3d-41.07636319870587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d3f56bb32587aaf%3A0x58828d7be426bc15!2s4%20Freshfields%20Place%2C%20Plimmerton%2C%20Porirua%205026!5e0!3m2!1sen!2snz!4v1594683449800!5m2!1sen!2snz"
            frameBorder={0}
            style={{ height: "100%", width: "100%", border: 0 }}
            allowFullScreen
            aria-hidden="false"
            title="Find us on Google maps"
          />
        </section>
      </Layout>
    </>
  )
}
